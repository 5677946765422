//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Pagination from "@/components/Pagination";
import { fetchAlarmLogList, checkAlarmLog, fetchAlarmDeviceList } from "../../../api/charge"; // import {} from 'iview-area'

export default {
  name: "AlarmLog",
  components: {
    Pagination: Pagination
  },
  data: function data() {
    return {
      // 查询字段
      searchForm: {
        page: 1,
        per_page: 20,
        keyword: "",
        alarm_id: "",
        status: ""
      },
      // 表格数据
      dataList: [],
      // 总数
      totalPage: 0,
      tableLoading: false,
      submitLoading: false,
      selectCount: 0,
      selectList: [],
      villageDialog: false,
      dialogForm: {
        id: "",
        alarm: {
          name: '',
          address: '',
          url: ''
        },
        status: "",
        status_str: "",
        type_str: "",
        check_status: "",
        check_time: "",
        created_at: ""
      },
      dialogTitle: "",
      deviceLoading: false,
      deviceOptions: [],
      btnLoading: false,
      rules: {
        check_status: [{
          required: true,
          message: "请选择审核状态"
        }]
      }
    };
  },
  created: function created() {
    this.searchForm.alarm_id = this.$route.query ? this.$route.query.alarm_id : '';
    this.remoteAlarmDevice(this.searchForm.alarm_id);
    this.getTableData();
  },
  methods: {
    getTableData: function getTableData() {
      var _this = this;

      this.tableLoading = true;
      var data = this.searchForm;
      fetchAlarmLogList(data).then(function (response) {
        _this.dataList = response.data;
        _this.totalPage = response.meta.total;
        _this.tableLoading = false;
      }).catch(function (err) {
        _this.tableLoading = false;

        _this.$message({
          type: "warning",
          message: err.data["message"]
        });
      });
    },
    handleSearch: function handleSearch() {
      console.log("搜索");
      this.searchForm.page = 1;
      this.getTableData();
    },
    handlePageCurrent: function handlePageCurrent(val) {
      this.searchForm.page = val;
      this.getTableData();
    },
    handlePageSize: function handlePageSize(val) {
      this.searchForm.per_page = val;
      this.searchForm.page = 1;
      this.getTableData();
    },
    resetForm: function resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    handleEdit: function handleEdit(row) {
      var data = Object.assign({}, row);
      this.villageDialog = true;
      this.dialogForm = data;
    },
    handleEditBtn: function handleEditBtn() {
      var _this2 = this;

      this.$refs["dialogForm"].validate(function (valid) {
        if (valid) {
          _this2.btnLoading = true;
          var data = Object.assign({}, _this2.dialogForm);
          checkAlarmLog({
            id: data.id,
            check_status: data.check_status
          }).then(function (res) {
            // 成功之后刷新或
            // this.dataList.unshift('返回的数据')
            _this2.$message({
              type: "success",
              message: "审核成功"
            });

            _this2.villageDialog = false;
            _this2.btnLoading = false;

            _this2.getTableData();
          }).catch(function (err) {
            _this2.btnLoading = false;

            _this2.$message({
              type: "error",
              message: err.data["message"]
            });
          });
        }
      });
    },
    resetContact: function resetContact() {
      this.dialogForm = {
        id: "",
        name: "",
        alarm_id: "",
        mobile: "",
        is_enable: true
      };
    },
    //搜索用户
    remoteAlarmDevice: function remoteAlarmDevice(query) {
      var _this3 = this;

      if (query !== "") {
        this.deviceLoading = true;
        setTimeout(function () {
          _this3.deviceLoading = false;
          var data = {
            keyword: query
          };
          fetchAlarmDeviceList(data).then(function (res) {
            _this3.deviceOptions = res.data;
          });
        }, 100);
      } else {
        this.memberOptions = [];
      }
    }
  }
};